/* You can add global styles to this file, and also import other style files */
/* You can add global styles to this file, and also import other style files */
@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  height: 100%;
}
body {
  overflow: hidden;
  height: 100%;
  width: 100%;
}

* {
  font-family: "Poppins", sans-serif;
}
.custom-shadow {
  box-shadow: 2px 2px 18px #888;
}

input {
  outline: none;
}
//   img, svg, video, canvas, audio, iframe, embed, object {
//     display: unset;
//   }
svg[display="none"] {
  display: none;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
